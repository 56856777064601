const styles = {
  root: {
    color: 'secondary.contrastText',
    flexGrow: 1,
    backgroundColor: 'secondary.main',
    py: {
      xs: 2, sm: 4,
    },
  },

  footerTitle: {
    '&.MuiTypography-strong': {
      color: 'text.white',
    },
    pr: { sm: 0, md: 5, lg: 10 },
  },

  description: {
    '&.MuiTypography-quote': {
      color: 'text.white',
    },
  },

  logo: {
    width: 150,
  },

  linkMakers: { cursor: 'pointer' },

  menuItem: {
    listStyleType: 'none',
    '& a': {
      textDecoration: 'none',
    },
  },

  rightSections: {
    justifyContent: 'right!important',
  },

  linktTitle: {
    '&.MuiTypography-h4': {
      color: 'text.white',
      cursor: 'pointer',
      fontSize: '17px!important',
    },
  },

  linkItem: {
    display: 'flex',
    my: 2,
    '&.MuiTypography-link-text': {
      color: 'text.white',
      textDecoration: 'underline',
    },
  },
  carsForSale: {
    '&.MuiTypography-body1': {
      color: 'text.white',
    },
  },
  copyright: {
    color: 'white',
    '&.MuiTypography-body1': {
      color: 'text.white',
    },
    '&.MuiTypography-body2': {
      color: 'text.white',
    },
  },

  bottomLinks: {
    '&.MuiTypography-link-text': {
      textDecoration: 'underline',
      color: 'text.white',

    },
  },

  carList: {
    cursor: 'pointer',
    minWidth: 'auto!important',
    padding: 'inherit',
    paddingY: { xs: '8px', md: '3px' },
    paddingX: { xs: '10px', md: '3px' },
    color: 'text.white',
    fontWeight: 400,
    fontSize: 14,
  },
}

export default styles
