import React, { memo } from 'react'
import Typography from '@mui/material/Typography'

export const TEXT_TYPES = {
  heading: {
    h1: 'heading_h1',
    h2: 'heading_h2',
    h3: 'heading_h3',
    h4: 'heading_h4',
  },
  body: {
    x1: 'body_x1',
    x2: 'body_x2',
  },
  strong: 'strong',
  quote: 'quote',
  button: {
    text: 'button_text',
  },
  link: {
    text: 'link_text',
  },
}

const TextComponent = React.forwardRef((props, ref) => {
  const {
    children, type, variant, component, style, ...rest
  } = props

  const variants = {
    [TEXT_TYPES.heading.h1]: 'h1',
    [TEXT_TYPES.heading.h2]: 'h2',
    [TEXT_TYPES.heading.h3]: 'h3',
    [TEXT_TYPES.heading.h4]: 'h4',
    [TEXT_TYPES.body.x1]: 'body1',
    [TEXT_TYPES.body.x2]: 'body2',
    [TEXT_TYPES.strong]: 'strong',
    [TEXT_TYPES.quote]: 'quote',
    [TEXT_TYPES.button.text]: 'button-text',
    [TEXT_TYPES.link.text]: 'link-text',
  }

  const componentMapping = {
    [TEXT_TYPES.heading.h1]: 'h1',
    [TEXT_TYPES.heading.h2]: 'h2',
    [TEXT_TYPES.heading.h3]: 'h3',
    [TEXT_TYPES.heading.h4]: 'h4',
    [TEXT_TYPES.body.x1]: 'p',
    [TEXT_TYPES.body.x2]: 'p',
    [TEXT_TYPES.strong]: 'p',
    [TEXT_TYPES.quote]: 'p',
    [TEXT_TYPES.button.text]: 'p',
    [TEXT_TYPES.link.text]: 'a',
  }

  return (
    <Typography
      ref={ref}
      component={component || componentMapping[type]}
      variant={variant || variants[type]}
      {...rest}
    >
      {children}
    </Typography>
  )
})

export default memo(TextComponent)
